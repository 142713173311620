.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: transparent;
  line-height: 1.47058824;
  cursor: pointer;
  color: #fff;
  padding: 30px 40px;
  font-weight: 500;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 2px solid #A2D9D1;
}
@media (max-width: 1199px) {
  .button {
    padding: 15px 20px;
  }
}
body.c1 .button {
  color: #A2D9D1;
  border-color: #A2D9D1;
}
body.c2 .button {
  color: #AC7979;
  border-color: #AC7979;
}
body.c3 .button {
  color: #B9B927;
  border-color: #B9B927;
}
body.c4 .button {
  color: #DD8B3D;
  border-color: #DD8B3D;
}
body.c5 .button {
  color: #919FC4;
  border-color: #919FC4;
}
body.c6 .button {
  color: #AD9B79;
  border-color: #AD9B79;
}
.button:hover,
.button:focus {
  border-color: #000000 !important;
  color: #000000 !important;
}
.button:active {
  border-color: #000000;
  color: #000000;
}
@font-face {
  font-family: 'Univers Next Pro';
  src: url('/extras/fonts/UniversNextPro-Medium.woff2') format('woff2'), url('/extras/fonts/UniversNextPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Univers Next Pro';
  src: url('/extras/fonts/UniversNextPro-Light.woff2') format('woff2'), url('/extras/fonts/UniversNextPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Univers Next Pro';
  src: url('/extras/fonts/UniversNextPro-Italic.woff2') format('woff2'), url('/extras/fonts/UniversNextPro-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #000000;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: 500;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 17px;
  font-family: 'Univers Next Pro', sans-serif;
  line-height: 1.47058824;
}
.unit caption {
  display: none;
}
.flag {
  background: #000000;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  position: relative;
}
@media (max-width: 1199px) {
  .unit {
    margin-top: 50px;
  }
}
@media (max-width: 767px) {
  .unit {
    margin-top: 25px;
  }
}
@media (max-width: 1199px) {
  .unit {
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .unit {
    margin-bottom: 25px;
  }
}
.unit .head,
.unit .body,
.unit .foot {
  float: left;
  width: 100%;
}
.unit.fold div.less,
.unit.fold div.more {
  float: left;
  width: 100%;
}
.unit.fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
  position: relative;
}
@media (max-width: 1199px) {
  .part {
    margin-top: 25px;
  }
}
@media (max-width: 767px) {
  .part {
    margin-top: 15px;
  }
}
@media (max-width: 1199px) {
  .part {
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .part {
    margin-bottom: 15px;
  }
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
}
#expo .cb-image-figure {
  height: 100%;
}
#expo .cb-image-container {
  height: 100% !important;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
div.load a.load {
  background-color: transparent;
  line-height: 1.47058824;
  cursor: pointer;
  color: #fff;
  padding: 30px 40px;
  font-weight: 500;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 2px solid #A2D9D1;
  display: inline-block;
  padding-right: 220px;
  background-size: 140px 72px;
  background-position: calc(100% - 50px) 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-pdf-petrol.svg);
}
@media (max-width: 1199px) {
  div.load a.load {
    padding: 15px 20px;
  }
}
body.c1 div.load a.load {
  color: #A2D9D1;
  border-color: #A2D9D1;
}
body.c2 div.load a.load {
  color: #AC7979;
  border-color: #AC7979;
}
body.c3 div.load a.load {
  color: #B9B927;
  border-color: #B9B927;
}
body.c4 div.load a.load {
  color: #DD8B3D;
  border-color: #DD8B3D;
}
body.c5 div.load a.load {
  color: #919FC4;
  border-color: #919FC4;
}
body.c6 div.load a.load {
  color: #AD9B79;
  border-color: #AD9B79;
}
div.load a.load:hover,
div.load a.load:focus {
  border-color: #000000 !important;
  color: #000000 !important;
}
div.load a.load:active {
  border-color: #000000;
  color: #000000;
}
body.c1 div.load a.load {
  background-image: url(/images/icon-pdf-petrol.svg);
}
body.c2 div.load a.load {
  background-image: url(/images/icon-pdf-red.svg);
}
body.c3 div.load a.load {
  background-image: url(/images/icon-pdf-olive.svg);
}
body.c4 div.load a.load {
  background-image: url(/images/icon-pdf-orange.svg);
}
body.c5 div.load a.load {
  background-image: url(/images/icon-pdf-blue.svg);
}
body.c6 div.load a.load {
  background-image: url(/images/icon-pdf-beige.svg);
}
@media (max-width: 1199px) {
  div.load a.load {
    padding: 15px 110px 15px 20px;
    background-size: 70px 36px;
    background-position: calc(100% - 20px) 50%;
  }
}
div.load a.load:hover,
div.load a.load:focus {
  background-image: url(/images/icon-pdf-black.svg) !important;
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid #000000;
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.cb-service {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.cb-service:first-child {
  margin-top: 0;
}
.cb-service:last-child {
  margin-bottom: 0;
}
.cb-youtube iframe,
.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  height: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding-bottom: 100vh;
  position: relative;
}
@media (max-width: 1199px) {
  #slides {
    padding-bottom: 70vh;
  }
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#expo div.link {
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 60px;
  z-index: 2;
  width: 58px;
  height: 58px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
@media (max-width: 1199px) {
  #expo a.link {
    width: 31px;
    height: 31px;
    bottom: 30px;
    top: unset;
  }
}
#expo a.link.prev {
  right: 100px;
}
@media (max-width: 1199px) {
  #expo a.link.prev {
    right: 70px;
  }
}
#expo a.link.next {
  right: 20px;
  background-image: url(/images/arrow-right-white.svg);
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: none;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: none;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #000000;
}
.cb-index-some {
  display: none;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'Univers Next Pro', sans-serif;
  line-height: 1.42857143;
  color: #000000;
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #000000;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  background-color: transparent;
  line-height: 1.47058824;
  cursor: pointer;
  color: #fff;
  padding: 30px 40px;
  font-weight: 500;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 2px solid #A2D9D1;
  line-height: 1.42857143;
}
@media (max-width: 1199px) {
  #disp .foot input,
  #disp .foot a {
    padding: 15px 20px;
  }
}
body.c1 #disp .foot input,
body.c1 #disp .foot a {
  color: #A2D9D1;
  border-color: #A2D9D1;
}
body.c2 #disp .foot input,
body.c2 #disp .foot a {
  color: #AC7979;
  border-color: #AC7979;
}
body.c3 #disp .foot input,
body.c3 #disp .foot a {
  color: #B9B927;
  border-color: #B9B927;
}
body.c4 #disp .foot input,
body.c4 #disp .foot a {
  color: #DD8B3D;
  border-color: #DD8B3D;
}
body.c5 #disp .foot input,
body.c5 #disp .foot a {
  color: #919FC4;
  border-color: #919FC4;
}
body.c6 #disp .foot input,
body.c6 #disp .foot a {
  color: #AD9B79;
  border-color: #AD9B79;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  border-color: #000000 !important;
  color: #000000 !important;
}
#disp .foot input:active,
#disp .foot a:active {
  border-color: #000000;
  color: #000000;
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1199px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'Univers Next Pro', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 40px;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #F0F0F0;
  min-height: 40px;
  padding: 5px 10px;
  -webkit-appearance: none;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 25px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 7.5px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'Univers Next Pro', sans-serif;
  font-size: 17px;
  line-height: 1.47058824;
  background: #F0F0F0;
  height: 40px;
  border: 1px solid #000000;
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form input.submit {
  float: right;
  margin-top: 25px;
  margin-bottom: 25px;
  background-color: transparent;
  line-height: 1.47058824;
  cursor: pointer;
  color: #fff;
  padding: 30px 40px;
  font-weight: 500;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 2px solid #A2D9D1;
}
@media (max-width: 1199px) {
  .unit.form input.submit {
    margin-top: 25px;
  }
}
@media (max-width: 767px) {
  .unit.form input.submit {
    margin-top: 15px;
  }
}
@media (max-width: 1199px) {
  .unit.form input.submit {
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .unit.form input.submit {
    margin-bottom: 15px;
  }
}
@media (max-width: 1199px) {
  .unit.form input.submit {
    padding: 15px 20px;
  }
}
body.c1 .unit.form input.submit {
  color: #A2D9D1;
  border-color: #A2D9D1;
}
body.c2 .unit.form input.submit {
  color: #AC7979;
  border-color: #AC7979;
}
body.c3 .unit.form input.submit {
  color: #B9B927;
  border-color: #B9B927;
}
body.c4 .unit.form input.submit {
  color: #DD8B3D;
  border-color: #DD8B3D;
}
body.c5 .unit.form input.submit {
  color: #919FC4;
  border-color: #919FC4;
}
body.c6 .unit.form input.submit {
  color: #AD9B79;
  border-color: #AD9B79;
}
.unit.form input.submit:hover,
.unit.form input.submit:focus {
  border-color: #000000 !important;
  color: #000000 !important;
}
.unit.form input.submit:active {
  border-color: #000000;
  color: #000000;
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: #000000;
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
}
@media (max-width: 1199px) {
  .unit.form div.cb-form-sent {
    margin-top: 25px;
  }
}
@media (max-width: 767px) {
  .unit.form div.cb-form-sent {
    margin-top: 15px;
  }
}
@media (max-width: 1199px) {
  .unit.form div.cb-form-sent {
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .unit.form div.cb-form-sent {
    margin-bottom: 15px;
  }
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
  font-size: 14px;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  background-color: transparent;
  line-height: 1.47058824;
  cursor: pointer;
  color: #fff;
  padding: 30px 40px;
  font-weight: 500;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 2px solid #A2D9D1;
}
@media (max-width: 1199px) {
  .two-step-verification-container a {
    padding: 15px 20px;
  }
}
body.c1 .two-step-verification-container a {
  color: #A2D9D1;
  border-color: #A2D9D1;
}
body.c2 .two-step-verification-container a {
  color: #AC7979;
  border-color: #AC7979;
}
body.c3 .two-step-verification-container a {
  color: #B9B927;
  border-color: #B9B927;
}
body.c4 .two-step-verification-container a {
  color: #DD8B3D;
  border-color: #DD8B3D;
}
body.c5 .two-step-verification-container a {
  color: #919FC4;
  border-color: #919FC4;
}
body.c6 .two-step-verification-container a {
  color: #AD9B79;
  border-color: #AD9B79;
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  border-color: #000000 !important;
  color: #000000 !important;
}
.two-step-verification-container a:active {
  border-color: #000000;
  color: #000000;
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: 500;
}
.table td {
  padding: 5px 10px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: 500;
}
@media (max-width: 767px) {
  .part--table .table {
    font-size: 2.21642764vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
#home {
  float: left;
  width: 233px;
  margin-top: 25px;
  margin-bottom: 22px;
}
.logo {
  width: 100%;
  height: auto;
}
#head {
  float: left;
  width: 100%;
  margin-top: 75px;
  margin-bottom: 75px;
}
@media (max-width: 1199px) {
  #head {
    margin-top: 75px;
  }
}
@media (max-width: 767px) {
  #head {
    margin-top: 40px;
  }
}
@media (max-width: 1199px) {
  #head {
    margin-bottom: 75px;
  }
}
@media (max-width: 767px) {
  #head {
    margin-bottom: 40px;
  }
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'Univers Next Pro', sans-serif;
  font-size: 17px;
  line-height: 1.47058824;
  color: #000000;
  overflow: hidden;
  hyphens: none;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 88%;
  width: 1048px;
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--header {
  border-bottom: 1px solid black;
  background-color: #FFFFFF;
  z-index: 2000;
  position: fixed;
  top: 0;
  width: 50vw;
}
.section--header .headcontent {
  float: left;
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
}
.cb-toggle-target-active .section--header {
  border-bottom: unset;
}
.cb-toggle-target-active .cb-scroll-triggered--active .section--header {
  border-bottom: unset;
}
@media (max-width: 1199px) {
  .section--header {
    width: 100vw;
  }
}
.navigation {
  float: right;
  position: relative;
}
.downlink {
  position: fixed;
  z-index: 1000;
  top: calc(100vh - 120px);
  left: calc(50% - 58px - 3vw);
  width: 58px;
  height: 58px;
  cursor: pointer;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-down-black.svg);
}
@media (max-width: 1199px) {
  .downlink {
    display: none;
  }
}
.cb-scroll-triggered--active .downlink {
  display: none;
}
.toplink {
  position: fixed;
  z-index: 1000;
  top: calc(100vh - 120px);
  left: calc(50% - 58px - 3vw);
  width: 58px;
  height: 58px;
  cursor: pointer;
  display: none;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-top-black.svg);
}
@media (max-width: 1199px) {
  .toplink {
    display: none;
  }
}
.cb-scroll-triggered--active .toplink {
  display: block;
}
main {
  float: left;
  width: 100%;
  position: relative;
  flex: 1 0 auto;
}
.container {
  float: left;
  width: 100vw;
  border-right: 1px solid #000000;
  min-height: 100vh;
}
.section--multimood {
  width: 100vw;
  height: 70vh;
  right: 0;
}
@media (max-width: 1199px) {
  .section--multimood {
    margin-top: 95px;
  }
}
body.c1 .section--multimood {
  background-color: #A2D9D1;
}
body.c2 .section--multimood {
  background-color: #AC7979;
}
body.c3 .section--multimood {
  background-color: #B9B927;
}
body.c4 .section--multimood {
  background-color: #DD8B3D;
}
body.c5 .section--multimood {
  background-color: #919FC4;
}
body.c6 .section--multimood {
  background-color: #AD9B79;
}
.section--slider {
  width: 100vw;
  height: 70vh;
  right: 0;
}
@media (max-width: 1199px) {
  .section--slider {
    margin-top: 95px;
  }
}
body.c1 .section--slider {
  background-color: #A2D9D1;
}
body.c2 .section--slider {
  background-color: #AC7979;
}
body.c3 .section--slider {
  background-color: #B9B927;
}
body.c4 .section--slider {
  background-color: #DD8B3D;
}
body.c5 .section--slider {
  background-color: #919FC4;
}
body.c6 .section--slider {
  background-color: #AD9B79;
}
#cb-cookie-warning {
  position: fixed;
  bottom: 48px;
  left: 48px;
  z-index: 1900;
  width: 500px;
  max-width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  font-family: 'Univers Next Pro', sans-serif;
  box-shadow: 0 0 4px rgba(120, 120, 120, 0.4);
  padding: 30px 30px;
  box-sizing: border-box;
  transform: translateX(-200%) translateY(200%);
  transition: all 0.5s 0.5s ease;
}
#cb-cookie-warning.cb-cookie-warning--hidden {
  display: none;
}
@media (max-width: 1199px) {
  #cb-cookie-warning {
    left: 0;
    bottom: 0;
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  #cb-cookie-warning {
    padding: 20px 5vw;
  }
}
.show-content #cb-cookie-warning {
  transform: translateX(0) translateY(0);
}
.cb-cookie-warning--container {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.cb-cookie-warning--text {
  float: left;
  width: 100%;
  box-sizing: border-box;
  color: #000000;
  text-align: center;
}
.cb-cookie-warning--text a {
  text-decoration: underline;
}
.cb-cookie-warning--actions {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
@media (max-width: 767px) {
  .cb-cookie-warning--actions {
    justify-content: center;
  }
}
.cb-cookie-warning__button {
  float: left;
  cursor: pointer;
}
#cb-cookie-warning__button--decline {
  order: 2;
  background-color: transparent;
  line-height: 1.47058824;
  cursor: pointer;
  color: #fff;
  padding: 30px 40px;
  font-weight: 500;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 2px solid #A2D9D1;
  opacity: 0.6;
  border-color: #000000 !important;
  color: #000000 !important;
  padding: 10px 20px;
}
@media (max-width: 1199px) {
  #cb-cookie-warning__button--decline {
    padding: 15px 20px;
  }
}
body.c1 #cb-cookie-warning__button--decline {
  color: #A2D9D1;
  border-color: #A2D9D1;
}
body.c2 #cb-cookie-warning__button--decline {
  color: #AC7979;
  border-color: #AC7979;
}
body.c3 #cb-cookie-warning__button--decline {
  color: #B9B927;
  border-color: #B9B927;
}
body.c4 #cb-cookie-warning__button--decline {
  color: #DD8B3D;
  border-color: #DD8B3D;
}
body.c5 #cb-cookie-warning__button--decline {
  color: #919FC4;
  border-color: #919FC4;
}
body.c6 #cb-cookie-warning__button--decline {
  color: #AD9B79;
  border-color: #AD9B79;
}
#cb-cookie-warning__button--decline:hover,
#cb-cookie-warning__button--decline:focus {
  border-color: #000000 !important;
  color: #000000 !important;
}
#cb-cookie-warning__button--decline:active {
  border-color: #000000;
  color: #000000;
}
#cb-cookie-warning__button--accept {
  order: 1;
  background-color: transparent;
  line-height: 1.47058824;
  cursor: pointer;
  color: #fff;
  padding: 30px 40px;
  font-weight: 500;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 2px solid #A2D9D1;
  border-color: #000000 !important;
  color: #000000 !important;
  padding: 10px 20px;
}
@media (max-width: 1199px) {
  #cb-cookie-warning__button--accept {
    padding: 15px 20px;
  }
}
body.c1 #cb-cookie-warning__button--accept {
  color: #A2D9D1;
  border-color: #A2D9D1;
}
body.c2 #cb-cookie-warning__button--accept {
  color: #AC7979;
  border-color: #AC7979;
}
body.c3 #cb-cookie-warning__button--accept {
  color: #B9B927;
  border-color: #B9B927;
}
body.c4 #cb-cookie-warning__button--accept {
  color: #DD8B3D;
  border-color: #DD8B3D;
}
body.c5 #cb-cookie-warning__button--accept {
  color: #919FC4;
  border-color: #919FC4;
}
body.c6 #cb-cookie-warning__button--accept {
  color: #AD9B79;
  border-color: #AD9B79;
}
#cb-cookie-warning__button--accept:hover,
#cb-cookie-warning__button--accept:focus {
  border-color: #000000 !important;
  color: #000000 !important;
}
#cb-cookie-warning__button--accept:active {
  border-color: #000000;
  color: #000000;
}
.footcontent {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 21px;
  line-height: 1.42857143;
  border-top: 1px solid #000000;
  margin-top: 75px;
  margin-bottom: 75px;
  padding-top: 100px;
  box-sizing: border-box;
}
@media (max-width: 1199px) {
  .footcontent {
    margin-top: 75px;
  }
}
@media (max-width: 767px) {
  .footcontent {
    margin-top: 40px;
  }
}
@media (max-width: 1199px) {
  .footcontent {
    margin-bottom: 75px;
  }
}
@media (max-width: 767px) {
  .footcontent {
    margin-bottom: 40px;
  }
}
@media (max-width: 1199px) {
  .footcontent {
    font-size: 16px;
    line-height: 1.125;
    flex-direction: column;
  }
}
#legal {
  float: left;
  width: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}
@media (max-width: 1199px) {
  #legal {
    margin-top: 20px;
  }
}
a {
  color: #000000;
  text-decoration: none;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a:hover,
a:focus {
  color: #000000;
}
.part.link .open {
  background-color: transparent;
  line-height: 1.47058824;
  cursor: pointer;
  color: #fff;
  padding: 30px 40px;
  font-weight: 500;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 2px solid #A2D9D1;
}
@media (max-width: 1199px) {
  .part.link .open {
    padding: 15px 20px;
  }
}
body.c1 .part.link .open {
  color: #A2D9D1;
  border-color: #A2D9D1;
}
body.c2 .part.link .open {
  color: #AC7979;
  border-color: #AC7979;
}
body.c3 .part.link .open {
  color: #B9B927;
  border-color: #B9B927;
}
body.c4 .part.link .open {
  color: #DD8B3D;
  border-color: #DD8B3D;
}
body.c5 .part.link .open {
  color: #919FC4;
  border-color: #919FC4;
}
body.c6 .part.link .open {
  color: #AD9B79;
  border-color: #AD9B79;
}
.part.link .open:hover,
.part.link .open:focus {
  border-color: #000000 !important;
  color: #000000 !important;
}
.part.link .open:active {
  border-color: #000000;
  color: #000000;
}
.part.link .open[href^="mailto:"] {
  padding-right: 220px;
  background-size: 140px 72px;
  background-position: calc(100% - 50px) 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-mail-petrol.svg);
}
body.c1 .part.link .open[href^="mailto:"] {
  background-image: url(/images/icon-mail-petrol.svg);
}
body.c2 .part.link .open[href^="mailto:"] {
  background-image: url(/images/icon-mail-red.svg);
}
body.c3 .part.link .open[href^="mailto:"] {
  background-image: url(/images/icon-mail-olive.svg);
}
body.c4 .part.link .open[href^="mailto:"] {
  background-image: url(/images/icon-mail-orange.svg);
}
body.c5 .part.link .open[href^="mailto:"] {
  background-image: url(/images/icon-mail-blue.svg);
}
body.c6 .part.link .open[href^="mailto:"] {
  background-image: url(/images/icon-mail-beige.svg);
}
@media (max-width: 1199px) {
  .part.link .open[href^="mailto:"] {
    padding: 15px 110px 15px 20px;
    background-size: 70px 36px;
    background-position: calc(100% - 20px) 50%;
  }
}
.part.link .open[href^="mailto:"]:hover,
.part.link .open[href^="mailto:"]:focus {
  background-image: url(/images/icon-mail-black.svg) !important;
}
h1 {
  hyphens: manual;
}
h2 {
  hyphens: auto;
}
h1,
h2 {
  font-size: 25px;
  line-height: 1.4;
  font-weight: 300;
}
h1 strong,
h2 strong {
  font-weight: 500;
}
p.skew {
  font-weight: normal;
  font-style: italic;
}
@media (max-width: 1199px) {
  .navigation .navi {
    display: none;
  }
}
.moblangnav {
  float: left;
  width: 100%;
}
.moblangnav div.sub1 {
  float: right;
  display: flex;
  flex-wrap: wrap;
  padding: 0 6vw;
  box-sizing: border-box;
  margin: 20px 0;
}
.moblangnav div.sub1 > .item {
  text-align: right;
  margin-right: 10px;
}
.moblangnav div.sub1 > .item.exit {
  margin-right: 0;
}
.moblangnav div.sub1 > .item > .menu.path {
  font-weight: 500;
}
.togglenavigation {
  position: absolute;
  top: 49px;
  right: 0;
  z-index: 2001;
  width: 25px;
  height: 20px;
  cursor: pointer;
}
.tline {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 2px;
  background-color: #000000;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateY(-10px);
}
body.cb-toggle-target-active .tline--2 {
  transform: translateY(-50%) rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: translateY(-50%) rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateY(10px);
}
.mobile-navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
  width: 100%;
  height: auto;
  background-color: #fff;
  overflow-y: scroll;
  transform: translateY(-100%);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateY(0);
}
.mobile-navi-animation {
  float: left;
  width: 100%;
  opacity: 0;
  transform: translateY(-100vh);
  transition: all 0s 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navi-animation {
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: translateY(0);
  opacity: 1;
}
.mobile-navi-animation div.navi {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}
.mobile-navi-animation div.navi > .cb-toggle {
  display: none;
}
.mobile-navi-animation div.navi > .item {
  float: left;
  position: relative;
}
.mobile-navi-animation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navi-animation div.navi > .item > .menu {
  float: right;
  display: block;
  color: #000000;
}
.mobile-navi-animation div.navi > .item > .menu.path {
  color: #000000;
}
.mobile-navi-animation div.sub2 {
  display: flex;
  flex-direction: column;
  padding: 0 6vw;
  box-sizing: border-box;
  margin: 108px 10px 20px 0;
}
.mobile-navi-animation div.sub2 > .item {
  text-align: right;
  margin-bottom: 5px;
}
.mobile-navi-animation div.sub2 > .item.exit {
  margin-bottom: 0;
}
.mobile-navi-animation div.sub2 > .item > .menu {
  font-size: clamp(16px, 1.5vw, 25px);
  line-height: 1.2;
  position: relative;
  padding-top: 8px;
  padding-bottom: 3px;
  padding-right: 22px;
  padding-left: 22px;
  box-sizing: border-box;
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
  z-index: 2;
}
.mobile-navi-animation div.sub2 > .item > .menu:after {
  content: '';
  position: absolute;
  z-index: -1;
  right: 0;
  top: 0;
  bottom: 0;
  width: 7px;
  height: 100%;
  background-color: #A2D9D1;
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navi-animation div.sub2 > .item > .menu.path {
  color: #FFFFFF;
}
.mobile-navi-animation div.sub2 > .item > .menu.path:after {
  width: 100%;
}
.mobile-navi-animation div.sub2 > .item.c1 > .menu:after {
  background-color: #A2D9D1;
}
.mobile-navi-animation div.sub2 > .item.c2 > .menu:after {
  background-color: #AC7979;
}
.mobile-navi-animation div.sub2 > .item.c3 > .menu:after {
  background-color: #B9B927;
}
.mobile-navi-animation div.sub2 > .item.c4 > .menu:after {
  background-color: #DD8B3D;
}
.mobile-navi-animation div.sub2 > .item.c5 > .menu:after {
  background-color: #919FC4;
}
.mobile-navi-animation div.sub2 > .item.c6 > .menu:after {
  background-color: #AD9B79;
}
.mobile-navi-animation div.sub3 {
  overflow: hidden;
  max-height: 0;
}
.mobile-navi-animation div.sub3 > .item {
  opacity: 0;
  transform: scale(0.8);
}
.mobile-navi-animation div.navi > .item.cb-toggle-target-active > div.navi {
  transition: none;
  max-height: 1000px;
  transition: max-height 2s;
}
.mobile-navi-animation div.navi > .item.cb-toggle-target-active > div.navi > .item {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s;
}
.mobile-navi-animation .cb-toggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
  width: 25px;
  height: 24px;
  background-size: 14px 14px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-down-black.svg);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navi-animation .cb-toggle.cb-toggle-active {
  transform: rotate(-180deg);
}
.slider {
  float: left;
  width: 100%;
}
#view.north--empty .slider {
  display: none;
}
.slider__width {
  float: left;
  width: 100%;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}
.slider__width::-webkit-scrollbar {
  display: none;
}
.slider__width .area {
  margin: 0!important;
  display: flex;
  flex-wrap: wrap;
}
#view .slider__width .area {
  display: flex;
  align-items: flex-start;
  flex-wrap: unset;
  white-space: nowrap;
  height: 100vh;
}
.slider__width .area .unit {
  width: 12vw;
  margin: 0;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
#edit .slider__width .area .unit {
  margin: 90px 20px 20px;
}
.slider__width .area .unit .part {
  margin: 0;
}
#view .slider__width .area .unit {
  border-right: 5px solid #FFFFFF;
  display: inline-block;
  width: auto;
  vertical-align: bottom;
  height: 100vh;
  max-width: initial;
}
#view .slider__width .area .unit .body {
  display: inline-block;
  width: auto;
  vertical-align: bottom;
  height: 100vh;
  max-width: initial;
}
#view .slider__width .area .unit .part.pict {
  display: inline-block;
  width: auto;
  vertical-align: bottom;
  height: 100vh;
  max-width: initial;
}
#view .slider__width .area .unit .part.pict .cb-image-figure,
#view .slider__width .area .unit .part.pict .cb-image-container {
  display: inline-block;
  width: auto;
  vertical-align: bottom;
  height: 100vh;
  max-width: initial;
}
#view .slider__width .area .unit .part.pict img {
  width: auto;
  height: 100vh;
  max-width: unset !important;
  max-height: unset !important;
}
#view .slider__width .area .unit:last-child {
  border-right: unset;
}
@media (max-width: 1199px) {
  #view .slider__width .area .unit {
    height: 70vh;
  }
  #view .slider__width .area .unit .body {
    height: 70vh;
  }
  #view .slider__width .area .unit .part.pict .cb-image-figure,
  #view .slider__width .area .unit .part.pict .cb-image-container {
    height: 70vh;
  }
  #view .slider__width .area .unit .part.pict img {
    height: 70vh;
  }
}
.slider__index {
  display: none;
}
.slider__arrows {
  position: absolute;
  top: 0;
  right: 0;
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 60px;
}
@media (max-width: 1199px) {
  .slider__arrows {
    top: unset;
    bottom: 0;
    margin-bottom: 30px;
  }
}
#edit .slider__arrows {
  display: none;
}
.slider__arrow {
  float: left;
  width: 58px;
  height: 58px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-left-white.svg);
  cursor: pointer;
  margin-right: 20px;
}
.slider__arrow.slider__arrow--next {
  background-image: url(/images/arrow-right-white.svg);
}
.slider__arrow.is-inactive {
  opacity: 0.3;
  cursor: default;
}
@media (max-width: 1199px) {
  .slider__arrow {
    width: 31px;
    height: 31px;
  }
}
/*# sourceMappingURL=./screen-small.css.map */